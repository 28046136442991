@import url('https://fonts.googleapis.com/css2?family=Ethnocentric&family=Proxima+Nova:wght@300&display=swap');

* {
	box-sizing: border-box;
}
body {
	margin: 0;
	color: #fff;
	background-size: cover;
	position: relative;
	font-family: 'Proxima Nova', sans-serif;
	background: #000;
}

body::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.App {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	font-family: 'Proxima Nova', sans-serif;
}

.wrapper {
	/* background: url('/public/cake.png') no-repeat center center; */
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-size: cover;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0.2;
}

.App-header {
	position: relative; /* add this to make the pseudo-element position relative to this element */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	padding: 40px;
	margin: 20px;
	min-width: 70%;
	/* max-width: 800px; */
	z-index: 1;
}

.App-header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	background: #000;
	background-size: cover; /* this will make sure the image covers the whole area */

	z-index: -1; /* this will put the image behind the content */
}



.copy {
	padding: 2rem;
}

.copy p {
	font-size: 1rem;
	margin-bottom: 1rem;
}

.copy h1 {
	font-size: 1.4rem;
	margin-bottom: 1rem;
}

.copy h2 {
	font-size: 0.8rem;
	margin-bottom: 1rem;
}

.App-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 20px;
	/* background: rgba(0, 0, 0, 0.9); */
	color: #fff;
	font-size: 14px;
	text-align: center;
	z-index: 9999;
}

.App-panel {
	display: flex;
	flex-direction: column;
	color: #fff;
	text-align: center;
	z-index: 2; /* Added this line */
}

.app-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.9);
	z-index: 1;
}

.App-header-top,
.App-header-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.App-header-bottom-left,
.App-header-bottom-right {
	display: flex;
	flex-direction: column;
	align-items: center;
}

hr {
	width: 80%;
	border: 1px solid #fff;
	margin: 20px 0;
}

.App-header a {
	color: #fff;
}

.app-link,
a {
	color: #fff;
}

h1,
p,
h2 {
	margin: 0.5em 0;
}

h1,
h2 {
	font-family: 'Ethnocentric', sans-serif;
}

img {
	max-width: 200px;
	margin-bottom: 1em;
}
